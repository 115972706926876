import * as React from 'react'

import { BackToTopButton } from 'sdk/back-to-top-button/BackToTopButton'
import { Content } from 'sdk/components'

import logo from '../assets/logo.svg'

import classes from './style.module.scss'


export const Footer: React.FC = () => {
  return (
    <Content
      mainWrapperClassName={classes.wrapper}
      className={classes.container}
    >
      <BackToTopButton className={classes.backToTopButton} />
      <div className={classes.headerContainer}>
        <img
          className={classes.logo}
          src={logo}
          width={225}
          height={41}
          alt="logo"
        />
      </div>

      <div className={classes.menuContainer}>
      </div>

      <hr />

      <div className={classes.footerContainer}>
        <div className={classes.legal}>
          © 2017-2025, GPU Audio, Inc. All rights reserved.
        </div>
      </div>
    </Content>
  )
}
