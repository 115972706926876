import * as React from 'react'
import classNames from 'classnames'

import { Content } from 'sdk/components'
import { Text, TextName } from 'sdk'
import { GradientContainer } from 'pages/soundscape-page/components'

import { steps } from './data'
import stepFilledBg from './assets/step-filled-bg.jpeg'

import classes from './style.module.scss'

export const OnePlatform: React.FC = () => {
  return (
    <Content className={classes.content}>
      <Text type={TextName.H2} className={classes.title}>
        Integrate, Test and Deploy in One Platform
      </Text>

      <div className={classes.steps}>
        { steps.map((step, index) => (
          <GradientContainer
            key={index}
            style={step.gradient ? {
              backgroundImage: `url(${stepFilledBg})`,
              backgroundSize: 'cover',
            } : {}}
            className={classNames(classes.step, { [classes.gradient]: step.gradient })}
          >
            <img
              className={classes.image}
              src={step.image}
              alt="image"
            />

            <Text
              className={classes.badge}
              type={TextName.Subtext}
            >
              Step { index + 1 }
            </Text>

            <Text
              type={TextName.TextBold}
            >
              { step.title }
            </Text>
          </GradientContainer>
        )) }
      </div>
    </Content>
  )
}
