import split from './assets/split.svg'
import tourVirtual from './assets/tour-virtual.svg'
import waveform from './assets/waveform.svg'

export const features = [
  {
    icon: split,
    title: 'GPU-Powered Upmix',
    description: 'Transforming stereo into ambient surround sound',
  },
  {
    icon: tourVirtual,
    title: 'Dynamic Audio Distribution',
    description: 'Across multiple speakers, creating a full 360°soundscape',
  },
  {
    icon: waveform,
    title: 'Cabin Acoustic Correction',
    description: 'Fine-tunes audio in real-time based on the in-car environment',
  },
]
