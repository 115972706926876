import * as React from 'react'
import classNames from 'classnames'
import { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import classes from './style.module.scss'

interface ComponentProps {
  resizable?: boolean;
  children: React.ReactNode;
  iconUnhovered?: string;
  iconHovered?: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  as?: 'button' | 'link' | 'router-link';
  to?: string;
  href?: string;
  target?: string;
}

export const Button: React.FC<ComponentProps> = (props) => {
  const {
    resizable,
    children,
    iconUnhovered,
    iconHovered,
    className,
    onClick,
    disabled,
    as,
    to,
    href,
    target,
  } = props
  const [isHovered, setIsHovered] = useState(false)

  const currentIcon = isHovered ? iconHovered : iconUnhovered
  const isCurrentIconProvided = !!currentIcon

  useEffect(() => {
    switch (as) {
      case 'router-link':
        if (!to) {
          console.warn(`${Button.name}: When 'as' is set to 'router-link', the 'to' prop is required.`)
        }
        break
      case 'link':
        if (!href) {
          console.warn(`${Button.name}: When 'as' is set to 'link', the 'href' prop is required.`)
        }
        break
      case 'button':
        if (href || to) {
          console.warn(`${Button.name}: The 'href' and 'to' props are not needed when 'as' is set to 'button'.`)
        }
        break
      default:
        console.warn(`
          ${Button.name}: The 'as' prop value '${as}' is not recognized.
           Valid values are 'button', 'link', or 'router-link'.`,
        )
    }
  }, [as, to, href])

  const handleMouseEnter = (): void => {
    if (!disabled) {
      setIsHovered(true)
    }
  }

  const handleMouseLeave = (): void => {
    setIsHovered(false)
  }

  const buttonClasses = classNames(
    classes.button,
    className,
    { [classes.hasUnhoveredIcon]: iconUnhovered },
    { [classes.hasHoveredIcon]: iconHovered && isHovered },
    { [classes.resizable]: resizable },
  )

  const renderContent = (): React.ReactElement => (
    <>
      { (iconHovered || iconUnhovered) && (
        <div className={classes.iconContainer}>
          { iconHovered && (
            <img
              src={iconHovered}
              alt="icon hovered"
              className={classNames(
                isCurrentIconProvided && isHovered ? classes.visible : classes.hidden,
                classes.icon,
              )}/>
          ) }

          { iconUnhovered && (
            <img
              src={iconUnhovered}
              alt="icon unhovered"
              className={classNames(
                isCurrentIconProvided && !isHovered ? classes.visible : classes.hidden,
                classes.icon,
              )}
            />
          ) }
        </div>
      ) }
      <span className={classes.text}>
        { children }
      </span>
      <span className={classes.serviceText}>
        { children }
      </span>
    </>
  )

  const renderButtonAsLink = (): ReactElement => (
    <a
      href={href}
      target={target}
      className={buttonClasses}
      rel="noreferrer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
    >
      { renderContent() }
    </a>
  )

  const renderButtonAsRouterLink = (): ReactElement => (
    <Link
      to={to ?? '/'}
      className={buttonClasses}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
    >
      { renderContent() }
    </Link>
  )

  const renderButton = (): React.ReactElement => (
    <button
      disabled={disabled}
      className={buttonClasses}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
    >
      { renderContent() }
    </button>
  )

  switch (as) {
    case 'link':
      return renderButtonAsLink()
    case 'router-link':
      return renderButtonAsRouterLink()
    default:
      return renderButton()
  }
}

Button.defaultProps = {
  resizable: false,
  disabled: false,
  as: 'button',
}
