export const features = [
  {
    prefix: 'Up to',
    value: '80%',
    description: 'Cost Reduction in Audio System Design and BOM Cost',
  },
  {
    prefix: 'Up to',
    value: '5X',
    description: 'Less Time Spent on Research and Development',
  },
  {
    value: 'Real-Time',
    description: 'High-quality Sound in Every Possible Scenario',
  },
]
