import React, { FC } from 'react'

import { DemoPopup } from 'pages/soundscape-page/components/demo-popup/DemoPopup'
import logo from 'pages/soundscape-page/assets/soundscape-voice.svg'

import bg from './assets/voice-bg.jpeg'

export const SoundscapeVoice: FC = () => {
  return (
    <DemoPopup
      backgroundImage={bg}
      logo={logo}
      description="The GPU-powered intercom enables seamless
      communication among passengers and allows voice commands
      from any seat in the car. Enhanced by advanced noise
      suppression technology, it ensures crystal-clear
      conversations by eliminating background noise and
      interference, making in-car communication effortless
      and uninterrupted"
    />
  )
}
