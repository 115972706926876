import { PopupId } from 'redux/popupReducer'
import feel from 'pages/soundscape-page/assets/soundscape-feel.svg'
import smart from 'pages/soundscape-page/assets/soundscape-smart.svg'
import zone from 'pages/soundscape-page/assets/soundscape-zone.svg'
import voice from 'pages/soundscape-page/assets/soundscape-voice.svg'
import safe from 'pages/soundscape-page/assets/soundscape-safe.svg'

import feelBg from './assets/feel-bg.jpeg'
import smartBg from './assets/smart-bg.jpeg'
import zoneBg from './assets/zone-bg.jpeg'
import voiceBg from './assets/voice-bg.jpeg'
import safeBg from './assets/safe-bg.jpeg'

export const solutions = [
  {
    logo: feel,
    bg: feelBg,
    description: 'Enjoy uninterrupted studio-quality sound with ' +
      'real-time noise suppression, delivering an immersive in-car ' +
      'audio experience that envelops both driver and passengers ' +
      'in concert-like soundscapes',
    popupId: PopupId.SoundscapeFeel,
  },
  {
    logo: smart,
    bg: smartBg,
    description: 'Provide a seamless experience by smoothly integrating ' +
      'various audio sources — sound alerts, navigation, and incoming ' +
      'calls — without causing disruptions',
    popupId: PopupId.SoundscapeSmart,
  },
  {
    logo: zone,
    bg: zoneBg,
    description: 'Personalized audio experience for each passenger, ' +
      'powered by GPU-driven sound separation technology, allowing ' +
      'everyone to enjoy their preferred media without any interference ',
    popupId: PopupId.SoundscapeZone,
  },
  {
    logo: voice,
    bg: voiceBg,
    description: 'The GPU-powered intercom enables clear communication ' +
      'among passengers and with external calls from any seat, ' +
      'eliminating background noise and interference',
    popupId: PopupId.SoundscapeVoice,
  },
  {
    logo: safe,
    bg: safeBg,
    description: 'Low-latency car collision prevention sound alerts, ' +
      'providing the driver with the sonic direction of a potential ' +
      'collision and a sense of urgency to react',
    popupId: PopupId.SoundscapeSafe,
  },
]
