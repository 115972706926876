import step1 from './assets/step-1.png'
import step2 from './assets/step-2.png'
import step3 from './assets/step-3.png'

export const steps = [
  {
    title: 'Build audio workflows in a user-friendly web tool',
    image: step1,
  },
  {
    title: 'Test changes in real-time with digital twins',
    image: step2,
  },
  {
    title: 'Deliver/update software-defined audio package at any stage',
    image: step3,
    gradient: true,
  },
]
