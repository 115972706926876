import * as React from 'react'
import { Howler } from 'howler'

import { Hero } from 'pages/soundscape-page/sections/hero/Hero'
import { GpuPowered } from 'pages/soundscape-page/sections/gpu-powered/GpuPowered'
import { BuildingBlocks } from 'pages/soundscape-page/sections/building-blocks/BuildingBlocks'
import { OnePlatform } from 'pages/soundscape-page/sections/one-platform/OnePlatform'
import { Solutions } from 'pages/soundscape-page/sections/solutions/Solutions'
import { Layout } from 'pages/soundscape-page/components/layout/Layout'
import { SoundscapePrime } from 'pages/soundscape-page/sections/soundscape-prime/SoundscapePrime'
import { BookDemo } from 'pages/soundscape-page/sections/book-demo/BookDemo'
import { ComeHearThis } from 'pages/soundscape-page/sections/come-hear-this/ComeHearThis'
import { MakeItHappen } from 'pages/soundscape-page/sections/make-it-happen/MakeItHappen'

export const SoundscapePage: React.FC = () => {
  React.useEffect(() => {
    // Howler global settings for audio players

    Howler.autoUnlock = true
    Howler.autoSuspend = false
  }, [])

  return (
    <Layout>
      <Hero />
      <GpuPowered />
      <BuildingBlocks />
      <SoundscapePrime />
      <ComeHearThis />
      <Solutions />
      <MakeItHappen />
      <OnePlatform />
      <BookDemo />
    </Layout>
  )
}
