import React, { FC } from 'react'
import classNames from 'classnames'

import { Text, TextName } from 'sdk'

import classes from './style.module.scss'

interface ComponentProps {
  active: boolean
  onToggle: () => void
}

export const Switch: FC<ComponentProps> = ({ active, onToggle }) => {
  return (
    <div
      className={classNames(classes.container, { [classes.active]: active })}
      onClick={onToggle}
    >
      <div className={classes.dot}></div>

      { active ? (
        <Text
          type={TextName.Text}
          className={classes.onText}
        >
          on
        </Text>
      ) : (
        <Text
          type={TextName.Text}
          className={classes.offText}
        >
          off
        </Text>
      ) }
    </div>
  )
}
