import React, { FC } from 'react'

import { DemoPopup } from 'pages/soundscape-page/components/demo-popup/DemoPopup'
import logo from 'pages/soundscape-page/assets/soundscape-zone.svg'

import bg from './assets/zone-bg.jpeg'

import { SOUNDSCAPE_PLAYERS } from '../data'

export const SoundscapeZone: FC = () => {
  return (
    <DemoPopup
      backgroundImage={bg}
      logo={logo}
      description="Personalized audio experience for each passenger,
      powered by advanced GPU-driven sound separation technology.
      This allows everyone to enjoy their preferred media without
      any interference from other audio sources. With the added
      benefit of personal sound capsules, each passenger can immerse
      themselves in their own distinct sound environment, making every
      journey a tailored experience"
      audioPlayer={SOUNDSCAPE_PLAYERS.ZONE_SEPARATION}
    />
  )
}
