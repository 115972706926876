import * as React from 'react'

import { Content } from 'sdk/components'
import { Text, TextName } from 'sdk'
import { ImageFeatures } from 'pages/soundscape-page/components/image-features/ImageFeatures'
import { AudioPlayer } from 'pages/soundscape-page/components'
import { SOUNDSCAPE_PLAYERS } from 'pages/soundscape-page/data'

import { SectionId } from 'core/interfaces'

import { features } from './data'
import soundscapePrimeLogo from './assets/soundscape-prime-logo.svg'
import carImage from './assets/car-image.jpeg'

import classes from './style.module.scss'

export const SoundscapePrime: React.FC = () => {
  return (
    <Content
      id={SectionId.GpuPowered}
      className={classes.content}
    >
      <img
        src={soundscapePrimeLogo}
        width={362}
        height={60}
        className={classes.logo}
        alt="logo"
      />

      <Text type={TextName.Text} className={classes.subtitle}>
        Delivers an immersive audio experience, creating a full 360°soundscape
      </Text>

      <ImageFeatures
        image={carImage}
        features={features}
      />

      <div className={classes.players}>
        <AudioPlayer
          player={SOUNDSCAPE_PLAYERS.GPU_UPMIX}
        />
        <AudioPlayer
          player={SOUNDSCAPE_PLAYERS.CABIN_ACOUSTIC}
        />
      </div>
    </Content>
  )
}
