import * as React from 'react'
import { useNavigation } from 'react-router-dom'

import { Content } from 'sdk/components'
import { LinearProgress } from 'sdk/header/linear-progress/LinearProgress'
import { BookDemoButton } from 'pages/soundscape-page/components'

import logo from '../assets/logo.svg'

import classes from './style.module.scss'

export const Header: React.FC = () => {
  const { state } = useNavigation()

  return (
    <>
      <Content
        element="header"
        mainWrapperClassName={classes.wrapper}
        className={classes.content}
      >
        <img
          className={classes.logo}
          src={logo}
          alt="logo"
        />

        <BookDemoButton className={classes.button} />
      </Content>
      <LinearProgress className={classes.progress} visible={state === 'loading'} />
    </>
  )
}
