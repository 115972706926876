import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Howl, Howler } from 'howler'
import classNames from 'classnames'

import { Text, TextName } from 'sdk'
import { useAudioPlayer } from 'pages/soundscape-page/context/AudioPlayerContext'

import { Switch } from './switch/Switch'
import { ProgressBar } from './progress-bar/ProgressBar'
import playIcon from './assets/play.svg'
import pauseIcon from './assets/pause.svg'

import { GradientContainer } from '../gradient-container/GradientContainer'

import classes from './style.module.scss'

interface AudioTrack {
  howl: Howl | null
  file: string
}

interface AudioTracks {
  a: AudioTrack
  b: AudioTrack
}

export interface PlayerProps {
  title: string
  audioFileA: string
  audioFileB: string
}

interface ComponentProps {
  player: PlayerProps
  className?: string
}

export const AudioPlayer: FC<ComponentProps> = ({ player: { title, audioFileA, audioFileB }, className }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTrack, setCurrentTrack] = useState<'a' | 'b'>('a')
  const { currentPlayer, setCurrentPlayer } = useAudioPlayer()
  const [progress, setProgress] = useState(0)
  const progressInterval = useRef<number>()

  const tracks = useRef<AudioTracks>({
    a: { howl: null, file: audioFileA },
    b: { howl: null, file: audioFileB },
  })

  useEffect(() => {
    const createTrack = (file: string): Howl => new Howl({
      src: file,
      preload: true,
      onend: () => setIsPlaying(false),
    })

    tracks.current.a.howl = createTrack(audioFileA)
    tracks.current.b.howl = createTrack(audioFileB)

    return () => {
      tracks.current.a.howl?.unload()
      tracks.current.b.howl?.unload()
    }
  }, [audioFileA, audioFileB])

  // Handle stopping other players
  useEffect(() => {
    if (currentPlayer !== title && isPlaying) {
      const activeTrack = tracks.current[currentTrack].howl
      if (activeTrack) {
        activeTrack.pause()
        setIsPlaying(false)
      }
    }
  }, [currentPlayer, title, isPlaying, currentTrack])

  const getCurrentTrack = useCallback(() => {
    return tracks.current[currentTrack].howl
  }, [currentTrack])

  const playPause = useCallback(async () => {
    const track = getCurrentTrack()
    if (!track) {
      return
    }

    // Resume AudioContext on user interaction
    if (Howler.ctx.state === 'suspended') {
      await Howler.ctx.resume()
    }

    if (isPlaying) {
      track.pause()
    } else {
      setCurrentPlayer(title)
      track.play()
    }
    setIsPlaying(!isPlaying)
  }, [getCurrentTrack, isPlaying, setCurrentPlayer, title])

  const switchTrack = useCallback(() => {
    const fromTrack = getCurrentTrack()
    if (!fromTrack) {
      return
    }

    const newTrackKey = currentTrack === 'a' ? 'b' : 'a'
    const toTrack = tracks.current[newTrackKey].howl
    if (!toTrack) {
      return
    }

    const seekTime = fromTrack.seek() as number
    fromTrack.pause()
    toTrack.seek(seekTime)
    if (isPlaying) {
      toTrack.play()
    }

    setCurrentTrack(newTrackKey)
  }, [getCurrentTrack, currentTrack, isPlaying])

  useEffect(() => {
    if (isPlaying) {
      progressInterval.current = window.setInterval(() => {
        const track = getCurrentTrack()
        if (track) {
          const duration = track.duration()
          const seek = track.seek() as number
          setProgress(seek / duration)
        }
      }, 100)
    } else if (progressInterval.current) {
      clearInterval(progressInterval.current)
    }

    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current)
      }
    }
  }, [isPlaying, getCurrentTrack])

  const handleSeek = useCallback((position: number) => {
    const track = getCurrentTrack()
    if (track) {
      const duration = track.duration()
      track.seek(duration * position)
    }
  }, [getCurrentTrack])

  return (
    <GradientContainer className={classNames(classes.container, className)}>
      <div className={classes.titleBlock}>
        <Text
          type={TextName.TextBold}
          className={classes.title}
        >
          { title }
        </Text>
        <Switch
          active={currentTrack === 'b'}
          onToggle={switchTrack}
        />
      </div>

      <div className={classes.controls}>
        <button
          className={classes.playPauseButton}
          onClick={playPause}
        >
          <img
            src={isPlaying ? pauseIcon : playIcon}
            alt="play/pause"
          />
        </button>
        <ProgressBar 
          progress={progress} 
          onSeek={handleSeek}
        />
      </div>
    </GradientContainer>
  )
}