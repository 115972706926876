import { PlayerProps } from './components/audio-player/AudioPlayer'

/* eslint-disable max-len */
export const CALENDLY_LINK = 'https://calendly.com/hello-gpu/30min'

export const SOUNDSCAPE_PLAYERS: Record<string, PlayerProps> = {
  GPU_UPMIX: {
    title: 'GPU-Powered Upmix',
    audioFileA: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/GPU-Powered%20Upmix%20OFF.mp3',
    audioFileB: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/GPU-Powered%20Upmix%20ON.mp3',
  },
  CABIN_ACOUSTIC: {
    title: 'Cabin Acoustic Correction',
    audioFileA: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/Cabin%20Acoustic%20Correction%20OFF.mp3',
    audioFileB: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/Cabin%20Acoustic%20Correction%20ON.mp3',
  },
  DYNAMIC_SPACE: {
    title: 'Dynamic Space Configuration',
    audioFileA: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/Dynamic%20Space%20Configuration%20OFF.mp3',
    audioFileB: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/Dynamic%20Space%20Configuration%20ON.mp3',
  },
  DYNRANGE_COMPRESSION: {
    title: 'DynRange Compression',
    audioFileA: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/DynRange%20Compression%20OFF.mp3',
    audioFileB: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/DynRange%20Compression%20ON.mp3',
  },
  ZONE_SEPARATION: {
    title: 'Zone Separation',
    audioFileA: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/Zone%20Separation%20OFF.mp3',
    audioFileB: 'https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/soundscape-audio/Zone%20Separation%20ON.mp3',
  },
}