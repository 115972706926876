import * as React from 'react'

import { Content } from 'sdk/components'
import { Text, TextName } from 'sdk'
import { GradientContainer } from 'pages/soundscape-page/components'

import { features } from './data'
import buildingBlocksInfo from './assets/building-blocks-info.jpeg'

import classes from './style.module.scss'

export const BuildingBlocks: React.FC = () => {
  return (
    <Content className={classes.content}>
      <Text type={TextName.H2} className={classes.title}>
        Absolute Creative Freedom. Zero Distortion
      </Text>

      <Text type={TextName.Text} className={classes.subtitle}>
        We&apos;ve built a complete library of building blocks
        to solve virtually any audio problem and develop nearly
        any audio functionality
      </Text>

      <img
        className={classes.info}
        src={buildingBlocksInfo}
        width={2368}
        height={1046}
        alt="info"
      />

      <div className={classes.features}>
        { features.map((feature, index) => (
          <GradientContainer
            key={index}
            className={classes.feature}
          >
            <Text
              type={TextName.Text}
              className={classes.featureTitle}
            >
              { feature.prefix ? `${feature.prefix} ` : '' }
              <span>
                { feature.value }
              </span>
            </Text>
            <Text type={TextName.Text}>
              { feature.description }
            </Text>
          </GradientContainer>
        )) }
      </div>
    </Content>
  )
}
