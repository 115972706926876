import React, { FC, useRef } from 'react'

import classes from './style.module.scss'

interface ComponentProps {
  progress: number
  onSeek: (position: number) => void
}

export const ProgressBar: FC<ComponentProps> = ({ progress, onSeek }) => {
  const progressRef = useRef<HTMLDivElement>(null)

  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!progressRef.current) {
      return
    }

    const rect = progressRef.current.getBoundingClientRect()
    const position = (e.clientX - rect.left) / rect.width

    onSeek(Math.max(0, Math.min(1, position)))
  }

  return (
    <div 
      ref={progressRef}
      className={classes.progressContainer} 
      onClick={handleClick}
    >
      <div 
        className={classes.progressBar}
        style={{ width: `${progress * 100}%` }}
      />
    </div>
  )
} 