import React, { FC } from 'react'

import { DemoPopup } from 'pages/soundscape-page/components/demo-popup/DemoPopup'
import logo from 'pages/soundscape-page/assets/soundscape-smart.svg'

import bg from './assets/smart-bg.jpeg'

import { SOUNDSCAPE_PLAYERS } from '../data'

export const SoundscapeSmart: FC = () => {
  return (
    <DemoPopup
      backgroundImage={bg}
      logo={logo}
      description="Smart Sound transitions provide a seamless
      experience by smoothly integrating various audio sources—sound
      alerts, navigation notifications, and incoming calls—without
      causing disruptions. Powered by GPU technology, the system
      also adjusts volume and compression levels in
      real-time based on noise levels"
      audioPlayer={SOUNDSCAPE_PLAYERS.DYNRANGE_COMPRESSION}
    />
  )
}
