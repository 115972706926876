import React, { FC } from 'react'

import { DemoPopup } from 'pages/soundscape-page/components/demo-popup/DemoPopup'
import logo from 'pages/soundscape-page/assets/soundscape-feel.svg'

import bg from './assets/feel-bg.jpeg'

import { SOUNDSCAPE_PLAYERS } from '../data'

export const SoundscapeFeel: FC = () => {
  return (
    <DemoPopup
      backgroundImage={bg}
      logo={logo}
      description="Full immersion is achieved through dynamic
      space configuration, where a GPU manages multiple channels
      across numerous speakers to emulate various acoustic
      environments. This concert-like audio experience is
      further enhanced by real-time noise suppression, which
      reduces distractions from engine noise, wind, and vibrations,
      creating a quieter space for an unparalleled in-car experience"
      audioPlayer={SOUNDSCAPE_PLAYERS.DYNAMIC_SPACE}
    />
  )
}
