import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Header } from './header/Header'
import { Footer } from './footer/Footer'

import classes from './style.module.scss'

interface ComponentProps {
  children: React.ReactNode
}

export const Layout: React.FC<ComponentProps> = ({ children }) => {
  const { pathname } = useLocation()
  const hash = location.hash.slice(1)

  React.useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0)
    }
  }, [pathname, hash])

  return (
    <>
      <Header />
      <main className={classes.main}>
        { children }
      </main>
      <Footer />
    </>
  )
}
