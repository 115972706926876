import React, { FC } from 'react'

import { Text, TextName } from 'sdk'
import { BookDemoButton } from 'pages/soundscape-page/components'
import { Content } from 'sdk/components'
import cta from 'pages/soundscape-page/assets/cta.jpeg'

import logo from './assets/logo.svg'

import classes from './style.module.scss'

export const ComeHearThis: FC = () => (
  <Content
    className={classes.content}
    style={{ backgroundImage: `url(${cta})` }}
  >
    <img
      className={classes.logo}
      width={204}
      height={55}
      src={logo}
      alt="logo"
    />

    <Text
      type={TextName.H1}
      className={classes.title}
    >
      Come Hear This
    </Text>

    <Text
      type={TextName.Text}
      className={classes.description}
    >
      Experience New Generation of Software-Defined Audio
    </Text>

    <BookDemoButton />
  </Content>
)
