import * as React from 'react'
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse'

import { Content } from 'sdk/components'
import underglow from 'shared/assets/underglow.png'
import { Text, TextName } from 'sdk'
import { BookDemoButton } from 'pages/soundscape-page/components'

import technologies from './assets/technologies.png'

import classes from './style.module.scss'

export const BookDemo: React.FC = () => {
  return (
    <Content
      mainWrapperClassName={classes.wrapper}
      className={classes.container}
    >
      <div className={classes.textBlock}>
        <Text
          type={TextName.H1}
          className={classes.heading}
        >
          Book a demo
        </Text>

        <Text
          type={TextName.Text}
          className={classes.description}
        >
          Experience personalized audio for each passenger,
          powered by accelerated audio technology, that
          delivers unmatched sound quality
        </Text>

        <BookDemoButton />
      </div>
      <MouseParallaxContainer
        globalFactorX={0.015}
        globalFactorY={0.015}
        resetOnLeave
        useWindowMouseEvents
        containerStyle={{
          overflow: 'visible',
          position: 'absolute',
        }}
        className={classes.imageContainer}
      >
        <img
          className={classes.underglow}
          src={underglow}
          width={1860}
          height={1658}
          alt="underglow"
        />
        <MouseParallaxChild className={classes.productsImage}>
          <img
            src={technologies}
            width={1739}
            height={1226}
            alt="products"
          />
        </MouseParallaxChild>
      </MouseParallaxContainer>
    </Content>
  )
}
