import React, { createContext, useContext, useState, ReactNode } from 'react'

interface AudioPlayerContextProps {
  currentPlayer: string | null
  setCurrentPlayer: (playerId: string | null) => void
}

const AudioPlayerContext = createContext<AudioPlayerContextProps | undefined>(undefined)

export const AudioPlayerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentPlayer, setCurrentPlayer] = useState<string | null>(null)

  return (
    <AudioPlayerContext.Provider value={{ currentPlayer, setCurrentPlayer }}>
      { children }
    </AudioPlayerContext.Provider>
  )
}

export const useAudioPlayer: () => AudioPlayerContextProps = () => {
  const context = useContext(AudioPlayerContext)
  if (!context) {
    throw new Error('useAudioPlayer must be used within an AudioPlayerProvider')
  }

  return context
}
