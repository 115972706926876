import React, { FC, useState, useEffect } from 'react'
import classNames from 'classnames'

import { AudioPlayer, PlayerProps } from 'pages/soundscape-page/components'
import { Text, TextName } from 'sdk'
import { CloseButton, Content } from 'sdk/components'
import { useAppDispatch } from 'redux/store'
import { closePopup } from 'redux/popupReducer'

import classes from './style.module.scss'

interface ComponentProps {
  backgroundImage: string
  logo: string
  description: string
  audioPlayer?: PlayerProps
}

export const DemoPopup: FC<ComponentProps> = (props) => {
  const {
    backgroundImage,
    logo,
    description,
    audioPlayer,
    ...rest
  } = props

  const dispatch = useAppDispatch()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Small delay to ensure DOM is ready
    requestAnimationFrame(() => {
      setIsVisible(true)
    })
  }, [])

  return (
    <Content
      element="div"
      mainWrapperClassName={classes.mainWrapper}
      wrapperClassName={classes.wrapper}
      className={classNames(
        classes.popup,
        { [classes.animate]: isVisible },
      )}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      {...rest}
    >
      <div className={classes.content}>
        <img
          className={classes.logo}
          src={logo}
          alt="logo"
          width={280}
          height={51}
        />

        <Text
          type={TextName.Text}
          className={classes.description}
        >
          { description }
        </Text>

        { audioPlayer && (
          <AudioPlayer
            className={classes.player}
            player={audioPlayer}
          />
        ) }
      </div>

      <CloseButton
        onClick={() => dispatch(closePopup())}
        className={classes.close}
      />
    </Content>
  )
}
