import React, { FC } from 'react'

import { Text, TextName } from 'sdk'
import { BookDemoButton } from 'pages/soundscape-page/components'
import { Content } from 'sdk/components'
import cta from 'pages/soundscape-page/assets/cta.jpeg'

import classes from './style.module.scss'

export const MakeItHappen: FC = () => (
  <Content
    className={classes.content}
    style={{ backgroundImage: `url(${cta})` }}
  >
    <Text
      type={TextName.TextBold}
      className={classes.text}
    >
      Can’t find the solution you’re looking for? Or
      want to create a custom solution tailored to your
      needs? Let’s make it happen!
    </Text>

    <BookDemoButton className={classes.button} />
  </Content>
)
