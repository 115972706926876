import * as React from 'react'

import { Content } from 'sdk/components'
import { Text, TextName } from 'sdk'
import { useAppDispatch } from 'redux/store'
import { showPopup } from 'redux/popupReducer'
import { useAudioPlayer } from 'pages/soundscape-page/context/AudioPlayerContext'

import { solutions } from './data'
import arrow from './assets/arrow.svg'

import classes from './style.module.scss'

export const Solutions: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setCurrentPlayer } = useAudioPlayer()

  return (
    <Content className={classes.content}>
      <Text type={TextName.H2} className={classes.title}>
        Discover More Soundscape Solutions
      </Text>

      <div className={classes.solutions}>
        { solutions.map((solution, index) => (
          <div
            onClick={() => {
              dispatch(showPopup(solution.popupId))
              setCurrentPlayer(null)
            }}
            key={index}
            className={classes.solution}
            style={{ backgroundImage: `url(${solution.bg})` }}
          >
            <img
              src={arrow}
              alt="arrow"
              width={24}
              height={24}
              className={classes.arrow}
            />

            <img
              className={classes.logo}
              src={solution.logo}
              width={280}
              height={51}
              alt="image"
            />

            <Text
              type={TextName.Text}
            >
              { solution.description }
            </Text>
          </div>
        )) }
      </div>
    </Content>
  )
}
