import * as React from 'react'

import { SimpleTitleButtonHero } from 'sdk/sections'
import { Button } from 'sdk/button/Button'

import { scrollToId } from 'core/utils'
import { SectionId } from 'core/interfaces'

import heroImage from './assets/soundscape-hero-image.jpeg'
import heroVideo from './assets/soundscape-hero-video.webm'

import classes from './style.module.scss'

export const Hero: React.FC = () => (
  <SimpleTitleButtonHero
    title={(
      <>
        Pioneering GPU-Powered
        <br />
        <span className={classes.gradientText}>
          Software-Defined Audio
        </span>
      </>
    )}
    description="Seamless integration of advanced audio
      capabilities into vehicles, ensuring that every
      journey delivers unparalleled sound quality and
      immersive experiences"
    actionButton={(
      <Button
        className={classes.button}
        onClick={() => scrollToId(SectionId.GpuPowered)}
      >
        Learn more
      </Button>
    )}
    imageLink={heroImage}
    videoLink={heroVideo}
    alignment="left"
    className={classes.content}
    mainWrapperClassName={classes.mainWrapper}
  />
)
