import React, { FC, HTMLAttributes } from 'react'
import classNames from 'classnames'

import classes from './style.module.scss'

interface ComponentProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

export const GradientContainer: FC<ComponentProps> = (props) => {
  const { children, className, ...rest } = props

  return (
    <div
      className={classNames(classes.container, className)}
      {...rest}
    >
      { children }
    </div>
  )
}
