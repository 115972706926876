import * as React from 'react'
import classNames from 'classnames'

import { Text, TextName } from 'sdk'
import { SocialNetworks } from 'sdk/social-networks/SocialNetworks'
import { MenuItemHeader } from 'sdk/menu-items/menu-item-header/MenuItemHeader'
import { routes } from 'apps/main/app-component/routes'

import './style.scss'

interface ComponentProps {
  menuOpened: boolean
  closeMenu(): void
}

export const MobileMenu: React.FC<ComponentProps> = (props) => {
  const {
    menuOpened,
    closeMenu,
  } = props

  return (
    <div className={classNames('mobile-menu', { 'mobile-menu--active': menuOpened })}>
      <div className="mobile-menu__links">
        { routes.map(({ title, path }, index) => (
          <MenuItemHeader
            key={index}
            link={path}
            onClick={closeMenu}
          >
            { title }
          </MenuItemHeader>
        )) }
      </div>

      <div className="mobile-menu__bottom">
        <SocialNetworks />
        <hr />
        <Text type={TextName.Subtext}>
          © 2017-2025, GPU Audio, Inc. All rights reserved.
        </Text>
      </div>
    </div>
  )
}
