import * as React from 'react'

import { Content } from 'sdk/components'
import { Text, TextName } from 'sdk'
import { ImageFeatures } from 'pages/soundscape-page/components/image-features/ImageFeatures'

import { SectionId } from 'core/interfaces'

import { features } from './data'
import gpuPoweredInfo from './assets/gpu-powered-info.jpeg'

import classes from './style.module.scss'

export const GpuPowered: React.FC = () => {
  return (
    <Content
      id={SectionId.GpuPowered}
      className={classes.content}
    >
      <Text type={TextName.H2} className={classes.title}>
        GPU Powered
      </Text>

      <Text type={TextName.Text} className={classes.subtitle}>
        Soundscape uses underutilized infotainment or ADAS GPUs to power software defined audio
      </Text>

      <ImageFeatures
        image={gpuPoweredInfo}
        features={features}
      />
    </Content>
  )
}
