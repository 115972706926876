import React, { FC } from 'react'

import { DemoPopup } from 'pages/soundscape-page/components/demo-popup/DemoPopup'
import logo from 'pages/soundscape-page/assets/soundscape-safe.svg'

import bg from './assets/safe-bg.jpeg'

export const SoundscapeSafe: FC = () => {
  return (
    <DemoPopup
      backgroundImage={bg}
      logo={logo}
      description="Low-latency car collision prevention spatial
      sound alerts, providing the driver with the sonic direction
      of a potential collision and a sense of urgency to react"
    />
  )
}
