import * as React from 'react'
import classNames from 'classnames'

import { Button } from 'sdk/button/Button'
import { CALENDLY_LINK } from 'pages/soundscape-page/data'

import classes from './style.module.scss'

interface ComponentProps {
  className?: string
}

export const BookDemoButton: React.FC<ComponentProps> = ({ className }) => {
  return (
    <Button
      className={classNames(classes.button, className)}
      as="link"
      href={CALENDLY_LINK}
      target="_blank"
    >
      Book A Demo
    </Button>
  )
}
