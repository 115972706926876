import * as React from 'react'
import classNames from 'classnames'

import { Content } from 'sdk/components'

import { Text, TextName } from '../../text/Text'

import classes from './style.module.scss'

interface ComponentProps {
  title: React.ReactNode
  description: string
  actionButton: React.ReactNode
  imageLink: string
  alignment?: 'center' | 'left'
  videoLink?: string
  className?: string
  mainWrapperClassName?: string
}

export const SimpleTitleButtonHero: React.FC<ComponentProps> = (props) => {
  const {
    title,
    description,
    actionButton,
    imageLink,
    videoLink,
    className,
    alignment,
    mainWrapperClassName,
  } = props

  return (
    <Content
      image={imageLink}
      videoLink={videoLink}
      mainWrapperClassName={classNames(classes.wrapper, mainWrapperClassName)}
      className={classNames(classes.container, classes[alignment!], className)}
    >
      <Text
        type={TextName.H1}
        className={classes.heading}
      >
        { title }
      </Text>

      <Text
        type={TextName.Text}
        className={classes.description}
      >
        { description }
      </Text>

      { actionButton }
    </Content>
  )
}

SimpleTitleButtonHero.defaultProps = {
  alignment: 'center',
}
