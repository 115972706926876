import tourVirtual from './assets/tour-virtual.svg'
import speakers from './assets/speakers.svg'
import sliders from './assets/sliders.svg'

export const features = [
  {
    icon: tourVirtual,
    title: '360° Real-Time Soundscape',
    description: 'Experience immersive, real-time audio processing with latency as low as 0.03 ms',
  },
  {
    icon: speakers,
    title: 'Studio-Quality Sound',
    description: 'Delivering up to 64 channels of high-fidelity audio for an unparalleled listening experience',
  },
  {
    icon: sliders,
    title: 'Next-Gen Features, At Scale',
    description: 'Unlock advanced features for all car models with a seamless, future-ready design',
  },
]
